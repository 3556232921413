import Link from 'next/link';
import cx from 'classnames';

function RotatingLinks({ isLearnLink, isStoryLink }) {
    const linkClasses = 'mx-2 font-medium text-[#222] md:mx-5 hover:underline text-lg dark:text-white';

    // function InheritLinks() {
    //     const linkClass = cx([linkClasses, 'hidden sm:inline']);
    //     return (
    //         <>
    //             <Link className={linkClass} href="/publications/inherit">
    //                 Articles
    //             </Link>
    //             <Link className={linkClass} href="/inherit#events">
    //                 Events
    //             </Link>
    //             <Link className={linkClass} href="/inherit#community">
    //                 Community
    //             </Link>
    //         </>
    //     );
    // }

    // if (inherit) return <InheritLinks />;

    return (
        <>
            <div className="flex">
                {/* <Link className={cx([linkClasses, 'hidden sm:block'])} href="/hanukkah" passHref>
                    Hanukkah
                </Link> */}
                <Link className={cx([linkClasses, !isLearnLink && 'hidden sm:block'])} href="/learning" passHref>
                    Learn
                </Link>
                <Link className={cx([linkClasses, !isStoryLink && 'hidden sm:block'])} href="/stories" passHref>
                    Our Stories
                </Link>
            </div>
        </>
    );
}

export default RotatingLinks;
